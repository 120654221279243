import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { coupleSelector } from 'entities/couple';
import { ModalContainer } from 'entities/modal';
import { userSelector } from 'entities/user';
import { weddingActions, weddingSelector, WsWeddingStates } from 'entities/wedding';
import { closeSocket, socket } from 'shared/api';
import { useShallowSelector } from 'shared/hooks';
import { collectionRingsMock, CollectionRingsProps } from 'shared/mocks';
import { EngagementType, SexType } from 'shared/types';
import { Layout } from 'shared/ui/layouts';
import { camelize } from 'shared/utils';
import './i18n';

import { routes, wsWeddingRoutes } from './providers/router/routes';
import { useWeb3Auth } from './providers/Web3AuthProvider';
import { GlobalProvider, RouteManager } from './providers';
import 'shared/styles/index.scss';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useWeb3Auth();
  const isHomePage = pathname === '/';
  const { isWitness, id: userId } = useShallowSelector(userSelector.getUser);
  const { engagementType } = useShallowSelector(coupleSelector.getCouple);
  const { id: engagementId } = useShallowSelector(weddingSelector.getWedding);

  useEffect(() => {
    if (engagementType && !isWitness) dispatch(weddingActions.getEngagement({ engagementType }));
  }, [dispatch, engagementType, isWitness]);

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event: MessageEvent) => {
        const data = camelize(JSON.parse(event.data));
        const weddingState = data.state as WsWeddingStates;

        if (isWitness && weddingState !== WsWeddingStates.ceremony) {
          closeSocket();
          logout();
        }

        dispatch(
          weddingActions.updateWedding(
            data?.couple?.engagementType === EngagementType.Person
              ? {
                  type: data?.couple?.engagementType,
                  ...data,
                }
              : {
                  type: data?.couple?.engagementType,
                  ...data,
                  members: [
                    data.members[0],
                    {
                      user: {
                        firstName: data?.couple.nftModel?.meta?.nickname,
                        lastName: '',
                        sex: data.members[0].user.sex === SexType.man ? SexType.woman : SexType.man,
                        profileImage: data?.couple.nftModel?.meta?.imageUrl,
                        isOnline: true,
                        contscts: data?.couple.nftModel?.contacts,
                      },
                      token: {
                        meta: {
                          imageUrl:
                            collectionRingsMock[(data?.collection?.name as keyof CollectionRingsProps) || 'gold'],
                        },
                      },
                    },
                  ],
                },
          ),
        );
        if (!isHomePage) navigate(wsWeddingRoutes[weddingState]);
      };
      const socketInterval = setInterval(() => dispatch(weddingActions.sendEmptyMessage()), 30000);
      socket.onclose = () => {
        clearInterval(socketInterval);
        // if(email||(isWitness && weddingState === WsWeddingStates.ceremony)){
        if (userId && !isWitness && engagementType) {
          dispatch(weddingActions.getEngagement({ engagementType }));
        } else {
          navigate(routes.home.path);
        }
      };
    }
    // return () => socket?.close();
  }, [dispatch, userId, engagementId, engagementType, isHomePage, isWitness, navigate]);

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        position: 'relative',
        minHeight: '100%',
      }}
    >
      <Layout>
        <RouteManager />
      </Layout>
      <ModalContainer />
    </Box>
  );
};

export default (
  <GlobalProvider>
    <App />
  </GlobalProvider>
);
