import { alpha } from '@mui/material/styles';

export const COLOR_ACCENT = '#2DF6DE';
export const COLOR_BLUE = '#3CDAEF';
export const COLOR_STROKE = '#5C5A75';
export const COLOR_BG = '#191836';
// export const COLOR_BG = '#440724';
 export const COLOR_BG_MOBILE = '#26244C';
// export const COLOR_BG_MOBILE = '#440724';
export const COLOR_BG_SECONDARY = '#323061';
export const COLOR_WH = '#FFFFFF';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_WHITE_ALPHA_21 = alpha('#FFFFFF', 0.21);
export const COLOR_WHITE_ALPHA_14 = alpha('#FFFFFF', 0.14);
export const COLOR_WHITE_ALPHA_5 = alpha('#FFFFFF', 0.05);
export const COLOR_WHITE_ALPHA_3 = alpha('#FFFFFF', 0.03);
export const COLOR_PURPURE = '#9B4DFF';
export const COLOR_VIOLET = '#6271F3';
export const COLOR_GRAY = '#C6C6C6';
export const COLOR_RED = '#FF4040';
export const COLOR_LIGHT_RED = '#F17A8B';
export const COLOR_ACCENT_LIGHT = '#A2F9EE';
export const COLOR_DARK = '#1C1931';
export const COLOR_BLACK = '#000000';
export const COLOR_DARK_AUTH = '#22214b';
export const COLOR_GRAY_BORDER = '#80808B';
export const COLOR_LIGHT_GRAY_INPUT = '#D8D8D8';
export const COLOR_LIGHT_GRAY_ALPHA_2 = alpha('#D9D9D9', 0.2);
export const COLOR_LIGTH_VIOLET = '#5a5c84';
export const COLOR_PINK = '#ff657e';
export const COLOR_PINK_ALPHA_85 = alpha('#ff657e', 0.85);
